import React, { useState, DragEvent, useEffect } from 'react';
import axios from 'axios';
import {
  ApplicationDocumentsListProps,
  ApplicationFile,
} from './ApplicationDocumentsList.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDowlandFiles from 'assets/img/IconDowlandFiles.svg';
import IconDeleteRed from 'assets/img/IconDeleteRed.svg';
import {
  Container,
  MainInfoBox,
  FileItem,
  FileLink,
  DeleteIcon,
  UploadBox,
  FileUploadArea,
  FileUploadInput,
  WarningMessage,
} from './ApplicationDocumentsList.style';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoItemText,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import CopyableText from 'components/common/CopyableText/CopyableText';
import { DocLinkBox } from '../RequestDetailsNew.style';
import { WarningModal } from 'components/common/WarningModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const downloadFile = (fileId: string) => {
  const url = `${BASE_URL}ui/file/${fileId}`;
  window.open(url, '_blank');
};

export const ApplicationDocumentsList: React.FC<ApplicationDocumentsListProps> = ({
  files,
  isEditing,
  onFilesChange,
  setIsUploading,
  isUploading,
  resetFiles,
  onDeleteFile,
  docLink,
}) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<ApplicationFile[]>(files ?? []);
  const [warningMessage, setWarningMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [externalLink, setExternalLink] = useState<string | null>(null);

  useEffect(() => {
    setUploadedFiles(files);
  }, [files]);

  const handleDeleteFile = (fileId: string) => {
    onDeleteFile(fileId); // Call parent handler
  };

  const handleFileUpload = async (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      setWarningMessage('File size exceeds the 5MB limit.');
      return;
    }

    setWarningMessage('');
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploading(true);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newFile: ApplicationFile = { file: response.data.uid, code: null, title: null };
      const updatedFiles = [...uploadedFiles, newFile];
      setUploadedFiles(updatedFiles);
      onFilesChange(updatedFiles); // Update parent component
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileUpload(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (resetFiles) {
      setUploadedFiles(files);
    }
  }, [resetFiles, files]);

  const handleDocLinkClick = () => {
    setExternalLink(docLink);
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExternalLink(null);
  };

  return (
    <Container>
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconDocuments} alt='icon' />
          Документы
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            {docLink && docLink !== '' && (
              <DocLinkBox>
                <MainInfoItemText>Ссылка на гугл папку</MainInfoItemText>
                <a onClick={handleDocLinkClick} style={{ cursor: 'pointer' }}>
                  {docLink}
                </a>
              </DocLinkBox>
            )}
            {uploadedFiles.length > 0 ? (
              uploadedFiles.map((file, index) => (
                <FileItem key={file.file}>
                  <FileLink onClick={() => downloadFile(file.file)}>
                    <img src={IconDowlandFiles} alt='download icon' />
                    Документ {index + 1}
                  </FileLink>
                  {isEditing && (
                    <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                      <img src={IconDeleteRed} alt='delete icon' />
                    </DeleteIcon>
                  )}
                </FileItem>
              ))
            ) : (
              <div>Документы отсутствуют</div>
            )}
            {isEditing && (
              <UploadBox>
                <label>Загрузите документы</label>
                <p>
                  Max file size is 5 mb. Supported file types are ( .jpg .png .pdf .xlsl .doc .docx){' '}
                </p>
                <FileUploadArea
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onClick={() => document.getElementById('fileInput')?.click()}>
                  Drag and drop files here or upload
                  <FileUploadInput
                    id='fileInput'
                    type='file'
                    onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
                  />
                </FileUploadArea>
                {isUploading && <p>Uploading...</p>}
                {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}
              </UploadBox>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {isModalOpen && (
        <WarningModal
          headerWarning='Предупреждение'
          textWarning='Вы переходите на внешнюю ссылку'
          buttonClose='Согласен'
          onClick={handleModalConfirm}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};
