import axios from 'axios';

export interface Filters {
  requestNum?: string;
  dateCreateStart?: any;
  dateCreateEnd?: any;
  whiteLabels?: string[];
  products?: string[];
  statuses?: string[];
  minSum?: number;
  maxSum?: number;
  minAvailSum?: number;
  maxAvailSum?: number;
  minRate?: string;
  maxRate?: string;
  minPeriod?: string;
  maxPeriod?: string;
  acceptNewOffers?: boolean;
  hasInvestorOffers?: boolean;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor`;

export const fetchTableBusinessList = async (page = 0, filters: Filters = {}) => {
  try {
    const params = new URLSearchParams({ page: page.toString() });

    const response = await axios.post(
      `${BASE_URL}/request?${params.toString()}`, // page остается в строке запроса
      filters, // фильтры передаются в теле запроса
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Ошибка загрузки данных:', error);
    throw error;
  }
};
