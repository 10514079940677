import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  BoxHeader,
  ControlBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
} from '../ApplicationBusinessList/ApplicationBusinessList.style';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { useNavigate } from 'react-router-dom';
import { fetchTableInvestorList } from 'components/api/applicationInvestorListApi';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';

interface PageChangeEventArgs {
  selected: number;
}

interface CodeClassMap {
  [key: string]: string;
}

interface WhiteLabel {
  code: string;
  name: string;
}

interface TableDataInvestor {
  id: string;
  num: string;
  businessName: string;
  requestedAmount: string;
  approvedAmount: string;
  product: string;
  status: string;
  whiteLabels: { text: string; className: string }[];
}

export const ApplicationInvestorList = () => {
  const navigate = useNavigate();

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<TableDataInvestor[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });

  const transformData = (data: any[]): TableDataInvestor[] => {
    const getCodeToClassname = (code: string): string => {
      const codeClassMap: CodeClassMap = {
        OMARKET: 'smartcash',
        ALL: 'all',
      };
      return codeClassMap[code] || code.toLowerCase();
    };

    return data.map((item) => ({
      id: item.id,
      num: item.num,
      businessName: item.businessName,
      requestedAmount:
        item.requestedAmount.toLocaleString('ru-KZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'null',
      approvedAmount:
        item.approvedAmount.toLocaleString('ru-KZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || '-',
      product: item.productName,
      status: item.statusName,
      whiteLabels: item.whiteLabels.map((label: WhiteLabel) => ({
        text: label.name,
        className: getCodeToClassname(label.code),
      })),
    }));
  };

  const fetchData = async (page = 0) => {
    try {
      const data = await fetchTableInvestorList(page);
      setTableData(transformData(data.content));
      setPaginationInfo({
        totalPages: Math.max(data.totalPages, 1),
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = useCallback(({ selected }: PageChangeEventArgs) => {
    fetchData(selected);
    setCurrentPage(selected);
  }, []);

  const filteredData = useMemo(() => {
    return searchValue
      ? tableData.filter((row) => row.num?.toLowerCase().includes(searchValue.toLowerCase()))
      : tableData;
  }, [searchValue, tableData]);

  const formatAmount = (amount: number | string) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <Container>
      <Title>Сделки на финансирование</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск Сделок'
            name='searchQuery'
          />
        </ControlBox>
        {/* <ButtonBox>
          <NewButton text='Экспортировать' className='white' />
        </ButtonBox> */}
      </BoxHeader>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Номер сделки</TableHeader>
            <TableHeader>Заемщик </TableHeader>
            <TableHeader>Запрашиваемая сумма</TableHeader>
            <TableHeader>Одобренная сумма</TableHeader>
            <TableHeader>Группы</TableHeader>
            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => (
            <TableRow key={row.id} onClick={() => navigate(`/request/${row.id}`)}>
              <TableCell>
                <StyledLink to={`/request/${row.id}`}>{row.num}</StyledLink>
              </TableCell>
              <TableCell>{row.businessName}</TableCell>
              <TableCell className='right'>{formatAmount(row.requestedAmount)} ₸</TableCell>
              <TableCell className='right'>{formatAmount(row.approvedAmount)} ₸</TableCell>
              <TableCell>
                <div>
                  {row.whiteLabels.map((label) => (
                    <TagsWhiteLabel
                      key={label.text}
                      text={label.text}
                      className={label.className}
                    />
                  ))}
                </div>
              </TableCell>
              <TableCell>{row.product}</TableCell>
              <TableCell>
                <StatusCircle $status={row.status} />
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={Math.max(paginationInfo.totalPages, 1)}
        currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
