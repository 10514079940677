export const DASHBOARD_URL = '/dashboard';
export const ORGANIZATION_URL = '/organization';
export const INVESTOR_SETTINGS_URL = '/investor-settings';
export const APPLICATIONS_URL = '/applications';
export const CONTRACTS_URL = '/contracts';
export const PAYMENTS_URL = '/payments';
export const BILLING_URL = '/billing';
export const SETTINGS_URL = '/settings';
export const HELPS_URL = '/helps';
export const NOTIFICATIONS_URL = '/notifications';
export const USER_URL = '/user';
export const ADMIN_URL = '/admin';
export const SUGGESTIONS_URL = '/suggestions';
