import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BlockInput,
  ButtonBox,
  Container,
  DropdownArrow,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  FileDeleteButton,
  FileUploadArea,
  FileUploadInput,
  FirstBlock,
  Header,
  LoadingIndicator,
  SecondBlock,
  UploadedFile,
} from './TwoStep.style';
import { InputText } from 'components/common/Input/InputText';
import { NewButton } from 'components/common/Button';
import { default as ArrowRight } from 'assets/img/ArrowRight.svg';
import { default as ArrowLeft } from 'assets/img/ArrowLeft.svg';
import { submitLoanRequest } from 'components/api/createApplicationApi';
import { Goal, ThreeStepProps, Industry } from './NewCreateApplication.interface';
import { TextArea } from 'components/common/TextArea/TextArea';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const ThreeStep: React.FC<ThreeStepProps> = ({
  setCurrentStep,
  selectedProductCode,
  sum,
  days,
  minSum,
  percent,
  selectAccountNum,
  selectedContract,
  uploadedFiles,
  selectedGroups,
  SetIdApplication,
  projectName,
  setProjectName,
  targetLoan,
  descriptionProject,
  setDescriptionProject,
  selectedCollateral,
  docLink,
}) => {
  const [uploadedFileLogo, setUploadedFileLogo] = useState<{ name: string; uid: string } | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<'goal' | 'industry' | null>(null);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);
  const [industrys, setIndustry] = useState<Industry[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<Industry | null>(null);

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await axios.get(`${BASE_URL}dictionary/get?name=funding_goal`, {
          withCredentials: true,
        });
        setGoals(response.data);
      } catch (error) {
        console.error('Error fetching funding goals:', error);
      }
    };

    fetchGoals();
  }, []);

  useEffect(() => {
    const fetchIndustry = async () => {
      try {
        const response = await axios.get(`${BASE_URL}dictionary/get?name=industry`, {
          withCredentials: true,
        });
        setIndustry(response.data);
      } catch (error) {
        console.error('Error fetching industry data:', error);
      }
    };

    fetchIndustry();
  }, []);

  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
      });
      const { uid } = response.data;
      setUploadedFileLogo({ name: file.name, uid });
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateGoogleDriveLink = (link: string) => {
    const googleDrivePattern = /google\.com\/drive\/folders\//;
    return googleDrivePattern.test(link);
  };

  const handleConfirm = async () => {
    setLoading(true);

    const formattedUploadedFiles = uploadedFiles.map((file) => ({ file: file.uid }));
    const logoFileId = uploadedFileLogo ? uploadedFileLogo.uid : null;

    const baseRequestBody = {
      account: selectAccountNum,
      requestedAmount: sum,
      requestPeriod: days,
      product: selectedProductCode,
      files: formattedUploadedFiles,
      requestedMinAmount: minSum,
      requestRate: percent,
      whiteLabels: selectedGroups,
      loanPurposeProject: projectName,
      loanPurpose: targetLoan,
      loanPurposeProjectDesc: descriptionProject,
      logoFileId: logoFileId,
      docLink: validateGoogleDriveLink(docLink) ? docLink : '', // Если ссылка некорректна, отправляем пустую строку
      industry: selectedIndustry ? { id: selectedIndustry.id } : null,
      collateral: selectedCollateral ? { id: selectedCollateral.id } : null,
    } as any;

    if (selectedProductCode === 'LOAN') {
      baseRequestBody.funding_goals = selectedGoal ? { id: selectedGoal.id } : null;
    } else if (selectedProductCode === 'TENDER') {
      baseRequestBody.funding_goals = null;
      if (selectedContract) {
        baseRequestBody.contracts = [
          {
            type: 'egz',
            num: selectedContract.contractNumberSys,
            id: selectedContract.id,
          },
        ];
      }
    }

    try {
      const responseData = await submitLoanRequest(baseRequestBody);
      SetIdApplication(responseData.id);
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error('Error in handleConfirm:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileDelete = () => {
    setUploadedFileLogo(null);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      handleFileUpload(files[0]);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const toggleDropdown = (dropdown: 'goal' | 'industry') => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleGoalSelect = (goal: Goal) => {
    setSelectedGoal(goal);
    setOpenDropdown(null);
  };

  const handleIndustrySelect = (industry: Industry) => {
    setSelectedIndustry(industry);
    setOpenDropdown(null);
  };

  const isFormValid = () => {
    if (!projectName || !descriptionProject || !selectedIndustry) {
      return false;
    }
    if (selectedProductCode === 'LOAN' && !selectedGoal) {
      return false;
    }
    return true;
  };

  return (
    <Container>
      <FirstBlock>
        <Header>Оформление заявки</Header>
        <BlockInput>
          <InputText
            title='Название проекта'
            placeholder='Apex Protocol'
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            type='text'
          />
          <DropdownContainer>
            <label>Индустрия</label>
            <DropdownHeader
              onClick={() => toggleDropdown('industry')}
              isOpen={openDropdown === 'industry'}
              $hasContent={!!selectedIndustry}
              $isDisabled={industrys.length === 0}>
              {selectedIndustry ? selectedIndustry.name : 'Выбрать индустрию'}
              <DropdownArrow isOpen={openDropdown === 'industry'} />
            </DropdownHeader>
            {openDropdown === 'industry' && (
              <DropdownListContainer>
                {industrys.map((industry) => (
                  <DropdownListItem
                    key={industry.id}
                    onClick={() => handleIndustrySelect(industry)}>
                    {industry.name}
                  </DropdownListItem>
                ))}
              </DropdownListContainer>
            )}
          </DropdownContainer>
        </BlockInput>
        {selectedProductCode === 'LOAN' && (
          <BlockInput>
            <DropdownContainer>
              <label>Цель займа</label>
              <DropdownHeader
                onClick={() => toggleDropdown('goal')}
                isOpen={openDropdown === 'goal'}
                $hasContent={!!selectedGoal}
                $isDisabled={goals.length === 0}>
                {selectedGoal ? selectedGoal.name : 'Выбрать цель займа'}
                <DropdownArrow isOpen={openDropdown === 'goal'} />
              </DropdownHeader>
              {openDropdown === 'goal' && (
                <DropdownListContainer>
                  {goals.map((goal) => (
                    <DropdownListItem key={goal.id} onClick={() => handleGoalSelect(goal)}>
                      {goal.name}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
          </BlockInput>
        )}
        <BlockInput>
          <TextArea
            title='Описание проекта'
            placeholder='Кратко расскажите историю своего бизнеса и опишите детально проект. Качественно описанные проекты привлекают на 30% больше инвестиций.'
            value={descriptionProject}
            onChange={(e) => setDescriptionProject(e.target.value)}
            className='maxApplication'
          />
        </BlockInput>
      </FirstBlock>
      <SecondBlock>
        <label>Загрузите обложку</label>
        <p>Ваша заявка будет смотреться выгоднее для инвестора</p>
        <p>Max file size is 200х200, 200kb. Supported file types are (.jpg .png)</p>
        <FileUploadArea
          onDrop={onDrop}
          onDragOver={onDragOver}
          onClick={() => document.getElementById('fileInput')?.click()}>
          Drag and drop files here or upload
          <FileUploadInput
            id='fileInput'
            type='file'
            onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
          />
        </FileUploadArea>
        {isLoading && <LoadingIndicator>Uploading...</LoadingIndicator>}
        <div>
          {uploadedFileLogo && (
            <UploadedFile key={uploadedFileLogo.uid}>
              {uploadedFileLogo.name}
              <FileDeleteButton onClick={handleFileDelete}>x</FileDeleteButton>
            </UploadedFile>
          )}
        </div>
        <ButtonBox>
          <NewButton
            text=''
            icon={ArrowLeft}
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
          />
          <NewButton
            text='Далее'
            icon={ArrowRight}
            onClick={handleConfirm}
            disabled={loading || !isFormValid()}
          />
        </ButtonBox>
      </SecondBlock>
    </Container>
  );
};

export default ThreeStep;
