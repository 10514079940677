import { styled, keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const ButtonStyle = styled.button`
  display: flex;
  border: none;
  padding: 10px 24px;
  gap: 20px;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: var(--Primary, #5784f7);
  box-shadow: 0px 4px 6.7px 0px rgba(87, 132, 247, 0.12);
  transition: background 0.3s ease;
  position: relative;
  align-items: center;
  &:hover {
    background: var(--primary-400, #0f62fe);
  }

  &:disabled {
    background: var(--Button-button-secondary, #393939);
    cursor: not-allowed;
    &:hover {
      background: var(--neutral-500, #848484);
    }
  }

  &.small {
    font-size: 14px;
  }

  &.white {
    border: 1px solid #5784f7;
    color: #5784f7;
    background: none;
    &:hover {
      border: 1px solid var(--Primary, #5784f7);
      background: #5784f7;
      color: #fff;
    }
    &:disabled {
      background: #eef1f3;
      color: #8aa1a6;
      border: 1px solid #cfe8e8;
    }
  }

  &.red {
    border: 1px solid #ff5353;
    color: #ffffff;
    background: #ff5353;
    &:hover {
      border: 1px solid var(--Primary, #ff5353);
      background: #ffffff;
      color: #ff5353;
    }
    &:disabled {
      background: #eef1f3;
      color: #8aa1a6;
      border: 1px solid #cfe8e8;
    }
  }

  &.loading::before {
    content: '';
    position: absolute;
    top: 0;
    opacity: 0.3;
    left: 0;
    height: 100%;
    background: #b2e8eb;
    animation: ${loadingAnimation} 2.5s linear forwards;
  }

  &.reject {
    border: none;
    color: #ff5353;
    background: none;
    box-shadow: none;

    &:hover {
      color: #ff7878;
    }
    &:disabled {
      color: #dee2e6;
    }
  }
`;

export { ButtonStyle };
