import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  BoxHeader,
  ControlBox,
  ButtonBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
  FilterBox,
  ActionFilterBox,
  Input,
  BoxInputHeader,
  BoxInputDescription,
  BoxInfoInput,
  StyledDatePicker,
  DatePickerWrapper,
  CalendarIcon,
  BoxDropDown,
  ButtonsBox,
} from './ApplicationBusinessList.style';
import { NewButton } from 'components/common/Button';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { useNavigate } from 'react-router-dom';
import { TableData } from './ApplicationBusinessList.interface';
import { fetchTableBusinessList, Filters } from 'components/api/applicationBusinessListApi';
import { default as IconTune } from 'assets/img/IconTune.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { Checkbox } from 'components/common/Checkbox';
import {
  DropdownArrow,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
} from 'components/NewCreateApplication/TwoStep.style';
import { useAppContext } from 'contexts/AppContext';
import axios from 'axios';

interface PageChangeEventArgs {
  selected: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const ApplicationBusinessList = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  const [applicationDateFrom, setApplicationDateFrom] = useState<Date | null>(null);
  const [applicationDateTo, setApplicationDateTo] = useState<Date | null>(null);
  const [financingPeriodFrom, setFinancingPeriodFrom] = useState('');
  const [financingPeriodTo, setFinancingPeriodTo] = useState('');
  const [requestedAmountFrom, setRequestedAmountFrom] = useState('');
  const [requestedAmountTo, setRequestedAmountTo] = useState('');
  const [entryThresholdFrom, setEntryThresholdFrom] = useState('');
  const [entryThresholdTo, setEntryThresholdTo] = useState('');
  const [interestRateFrom, setInterestRateFrom] = useState('');
  const [interestRateTo, setInterestRateTo] = useState('');
  const [isReceivingOffers, setIsReceivingOffers] = useState(false);
  const [hasInvestorOffers, setHasInvestorOffers] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const [selectedWhiteLabels, setSelectedWhiteLabels] = useState<string[]>([]);
  const [isWhiteLabelDropdownOpen, setIsWhiteLabelDropdownOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState<{ code: string; name: string }[]>([]);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});
  const [inputHasContent, setInputHasContent] = useState({
    dealNumber: false,
    borrower: false,
    requestedAmountFrom: false,
    requestedAmountTo: false,
    entryThresholdFrom: false,
    entryThresholdTo: false,
    interestRateFrom: false,
    interestRateTo: false,
    financingPeriodFrom: false,
    financingPeriodTo: false,
  });

  useEffect(() => {
    if (isFilterBoxOpen) {
      const fetchStatuses = async () => {
        try {
          const response = await axios.get(`${BASE_URL}dictionary/get?name=request_statuses`, {
            withCredentials: true,
          });
          setStatuses(response.data);
        } catch (error) {
          console.error('Ошибка загрузки статусов:', error);
        }
      };

      // Вызов функции
      fetchStatuses();
    }
  }, [isFilterBoxOpen]);

  const applyFilters = async () => {
    const statusCodes = selectedStatuses.map((status) => status.code);

    const filters: Filters = {
      requestNum: searchValue,
      dateCreateStart: applicationDateFrom?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: applicationDateTo?.toISOString().split('T')[0] || undefined,
      whiteLabels: selectedWhiteLabels,
      products: selectedProduct ? [selectedProduct] : [],
      statuses: statusCodes,
      minSum: requestedAmountFrom ? parseFloat(requestedAmountFrom) : undefined,
      maxSum: requestedAmountTo ? parseFloat(requestedAmountTo) : undefined,
      minAvailSum: entryThresholdFrom ? parseFloat(entryThresholdFrom) : undefined,
      maxAvailSum: entryThresholdTo ? parseFloat(entryThresholdTo) : undefined,
      minRate: interestRateFrom,
      maxRate: interestRateTo,
      minPeriod: financingPeriodFrom,
      maxPeriod: financingPeriodTo,
    };

    setCurrentFilters(filters);

    try {
      const data = await fetchTableBusinessList(currentPage, filters); // Передаем объект filters
      setTableData(transformedData(data.content)); // Обновить данные таблицы
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка применения фильтров:', error);
    }
  };

  const clearFilters = () => {
    setSearchValue('');
    setApplicationDateFrom(null);
    setApplicationDateTo(null);
    setSelectedWhiteLabels([]);
    setSelectedProduct('');
    setSelectedStatuses([]);
    setRequestedAmountFrom('');
    setRequestedAmountTo('');
    setEntryThresholdFrom('');
    setEntryThresholdTo('');
    setInterestRateFrom('');
    setInterestRateTo('');
    setFinancingPeriodFrom('');
    setFinancingPeriodTo('');
    setIsReceivingOffers(false);
    setHasInvestorOffers(false);

    setCurrentFilters({});
    setCurrentPage(0); // Сбрасываем страницу на 0
    fetchTableData(0, {}); // Загружаем данные для 0-й страницы без фильтров
  };

  const toggleProductDropdown = () => {
    setProductDropdownOpen((prev) => !prev);
  };

  const handleProductSelect = (product: any) => {
    setSelectedProduct(product);
    setProductDropdownOpen(false);
  };

  const handleInputChange = (field: string, value: string) => {
    setInputHasContent((prev) => ({
      ...prev,
      [field]: value !== '',
    }));
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const toggleWhiteLabelDropdown = () => {
    setIsWhiteLabelDropdownOpen((prev) => !prev);
  };

  const handleWhiteLabelSelect = (name: string) => {
    setSelectedWhiteLabels((prev) =>
      prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name],
    );
  };

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen((prev) => !prev);
  };

  const handleStatusSelect = (status: { code: string; name: string }) => {
    setSelectedStatuses((prev) =>
      prev.find((item) => item.code === status.code)
        ? prev.filter((item) => item.code !== status.code)
        : [...prev, status],
    );
  };

  const transformedData = (data: any[]) => {
    return data.map((item) => ({
      num: item.num,
      id: item.id,
      product: item.productName,
      requestedAmount: item.requestedAmount
        ? item.requestedAmount.toLocaleString('ru-KZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 'null',
      approvedAmount: item.approvedAmount
        ? item.approvedAmount.toLocaleString('ru-KZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : '-',
      status: item.statusName,
    }));
  };

  const fetchTableData = async (page = 0, filters: Filters = currentFilters) => {
    try {
      const data = await fetchTableBusinessList(page, filters);
      setTableData(transformedData(data.content));
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const handlePageChange = useCallback(
    ({ selected }: PageChangeEventArgs) => {
      fetchTableData(selected, currentFilters);
      setCurrentPage(selected);
    },
    [currentFilters],
  );

  const filteredData = useMemo(() => {
    return searchValue
      ? tableData.filter((row) => row.num?.toLowerCase().includes(searchValue.toLowerCase()))
      : tableData;
  }, [searchValue, tableData]);

  const getStatusHeaderText = () => {
    const count = selectedStatuses.length;
    if (count === 0) return 'Выбрать Статус';
    if (count === 1) return 'Выбран 1 статус';
    return `Выбрано ${count} статуса`;
  };

  return (
    <Container>
      <Title>Сделки на финансирование</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск Сделок'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='icon' />
          </FilterBox>
        </ControlBox>
        <ButtonBox>
          <NewButton
            text='Создать сделку'
            className='small'
            onClick={() => navigate('/create-application')}
          />
        </ButtonBox>
      </BoxHeader>
      {/* {isFilterBoxOpen && (
        <ActionFilterBox>
          <BoxInputDescription>
            <p>Дата заявки</p>
            <BoxInfoInput>
              <span>С</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={applicationDateFrom}
                  onChange={(date: Date | null) => setApplicationDateFrom(date)}
                  placeholderText='Выберите дату'
                />
                <CalendarIcon src={CalendarIcons} alt='calendar icon' />
              </DatePickerWrapper>
              <span>По</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={applicationDateTo}
                  onChange={(date: Date | null) => setApplicationDateTo(date)}
                  placeholderText='Выберите дату'
                />
                <CalendarIcon src={CalendarIcons} alt='calendar icon' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <p>Срок финансирования</p>
            <BoxInfoInput>
              <span>От</span>
              <Input
                placeholder='1'
                value={financingPeriodFrom}
                $hasContent={inputHasContent.financingPeriodFrom}
                onChange={(e) => {
                  handleInputChange('financingPeriodFrom', e.target.value);
                  setFinancingPeriodFrom(e.target.value);
                }}
              />
              <span>До</span>
              <Input
                placeholder='30'
                value={financingPeriodTo}
                $hasContent={inputHasContent.financingPeriodTo}
                onChange={(e) => {
                  handleInputChange('financingPeriodTo', e.target.value);
                  setFinancingPeriodTo(e.target.value);
                }}
              />
            </BoxInfoInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <p>Запрашиваемая сумма</p>
            <BoxInfoInput>
              <span>От</span>
              <Input
                placeholder='2 000 000'
                value={requestedAmountFrom}
                $hasContent={inputHasContent.requestedAmountFrom}
                onChange={(e) => {
                  handleInputChange('requestedAmountFrom', e.target.value);
                  setRequestedAmountFrom(e.target.value);
                }}
              />
              <span>До</span>
              <Input
                placeholder='7 000 000'
                value={requestedAmountTo}
                $hasContent={inputHasContent.requestedAmountTo}
                onChange={(e) => {
                  handleInputChange('requestedAmountTo', e.target.value);
                  setRequestedAmountTo(e.target.value);
                }}
              />
            </BoxInfoInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <p>Минимальный порог входа</p>
            <BoxInfoInput>
              <span>От</span>
              <Input
                placeholder='100 000'
                value={entryThresholdFrom}
                $hasContent={inputHasContent.entryThresholdFrom}
                onChange={(e) => {
                  handleInputChange('entryThresholdFrom', e.target.value);
                  setEntryThresholdFrom(e.target.value);
                }}
              />
              <span>До</span>
              <Input
                placeholder='200 000'
                value={entryThresholdTo}
                $hasContent={inputHasContent.entryThresholdTo}
                onChange={(e) => {
                  handleInputChange('entryThresholdTo', e.target.value);
                  setEntryThresholdTo(e.target.value);
                }}
              />
            </BoxInfoInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <p>Процентная ставка</p>
            <BoxInfoInput>
              <span>От</span>
              <Input
                placeholder='7%'
                value={interestRateFrom}
                $hasContent={inputHasContent.interestRateFrom}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(value)) {
                    handleInputChange('interestRateFrom', value);
                    setInterestRateFrom(value);
                  }
                }}
              />
              <span>До</span>
              <Input
                placeholder='20%'
                value={interestRateTo}
                $hasContent={inputHasContent.interestRateTo}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(value)) {
                    handleInputChange('interestRateTo', value);
                    setInterestRateTo(value);
                  }
                }}
              />
            </BoxInfoInput>
          </BoxInputDescription>
          <BoxInfoInput>
            <Checkbox
              checked={isReceivingOffers}
              onChange={() => setIsReceivingOffers((prev) => !prev)}
            />
            <span>Ведется прием предложений</span>
          </BoxInfoInput>
          <BoxInfoInput>
            <Checkbox
              checked={hasInvestorOffers}
              onChange={() => setHasInvestorOffers((prev) => !prev)}
            />
            <span>Есть предложения инвесторов</span>
          </BoxInfoInput>
          <BoxDropDown>
            <DropdownContainer>
              <DropdownHeader
                onClick={toggleProductDropdown}
                isOpen={isProductDropdownOpen}
                $hasContent={!!selectedProduct}>
                {selectedProduct || 'Выбрать продукт'}
                <DropdownArrow isOpen={isProductDropdownOpen} />
              </DropdownHeader>
              {isProductDropdownOpen && (
                <DropdownListContainer>
                  <DropdownListItem onClick={() => handleProductSelect('Заемное финансирование')}>
                    Заемное финансирование
                  </DropdownListItem>
                  <DropdownListItem onClick={() => handleProductSelect('Тендерное финансирование')}>
                    Тендерное финансирование
                  </DropdownListItem>
                </DropdownListContainer>
              )}
            </DropdownContainer>
            <DropdownContainer>
              <DropdownHeader
                onClick={toggleWhiteLabelDropdown}
                isOpen={isWhiteLabelDropdownOpen}
                $hasContent={selectedWhiteLabels.length > 0}
                $isDisabled={userDataLight?.whiteLabel === 0}>
                {selectedWhiteLabels.length > 0
                  ? selectedWhiteLabels.map((code) => code).join(', ')
                  : 'Выбрать White Label'}
                <DropdownArrow isOpen={isWhiteLabelDropdownOpen} />
              </DropdownHeader>
              {isWhiteLabelDropdownOpen && (
                <DropdownListContainer>
                  {userDataLight.whiteLabel.map((label: any) => (
                    <DropdownListItem
                      key={label.code}
                      onClick={() => handleWhiteLabelSelect(label.name)}
                      $isSelected={selectedWhiteLabels.includes(label.name)}>
                      {label.name}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
            <DropdownContainer>
              <DropdownHeader
                onClick={toggleStatusDropdown}
                isOpen={isStatusDropdownOpen}
                $hasContent={selectedStatuses.length > 0}>
                {getStatusHeaderText()}
                <DropdownArrow isOpen={isStatusDropdownOpen} />
              </DropdownHeader>
              {isStatusDropdownOpen && (
                <DropdownListContainer>
                  {statuses.map((status: any) => (
                    <DropdownListItem
                      key={status.id}
                      onClick={() => handleStatusSelect(status)}
                      $isSelected={selectedStatuses.includes(status.name)}>
                      {status.name}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
          </BoxDropDown>
          <ButtonsBox>
            <NewButton text='Очистить' className='white' onClick={clearFilters} />
            <NewButton text='Применить фильтры' onClick={applyFilters} />
          </ButtonsBox>
        </ActionFilterBox>
      )} */}
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Номер сделки</TableHeader>
            <TableHeader>Запрашиваемая сумма</TableHeader>
            <TableHeader>Одобренная сумма</TableHeader>
            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => (
            <TableRow key={row.id} onClick={() => navigate(`/req/${row.id}`)}>
              <TableCell>
                <StyledLink to={`/req/${row.id}`}>{row.num}</StyledLink>
              </TableCell>
              <TableCell className='right'>{row.requestedAmount} ₸</TableCell>
              <TableCell className='right'>{row.approvedAmount} ₸</TableCell>
              <TableCell>{row.product}</TableCell>
              <TableCell>
                <StatusCircle $status={row.status} />
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={paginationInfo.totalPages}
        currentPage={paginationInfo.currentPage}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
