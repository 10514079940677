import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  FirstBlock,
  BlockInput,
  Header,
  CalendarBox,
  Calendar,
  StyledDatePickerWrapper,
  DatePickerWrapper,
  StyledInputWithIcon,
  StyledInput,
  CalendarIconWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  DropdownArrow,
  AddAccountItem,
  BoxTag,
  Tag,
  Tags,
  SecondBlock,
  FileUploadArea,
  UploadedFile,
  FileDeleteButton,
  FileUploadInput,
  LoadingIndicator,
  ButtonBox,
  FactoryBox,
} from './TwoStep.style';
import { InputText } from 'components/common/Input/InputText';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { default as ArrowRight } from 'assets/img/ArrowRight.svg';
import { default as ArrowLeft } from 'assets/img/ArrowLeft.svg';
import { ru } from 'date-fns/locale';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button';
import {
  Account,
  CollateralOption,
  Contract,
  KBE,
  TwoStepProps,
  WhiteLabelOption,
} from './NewCreateApplication.interface';
import { ErrorModal } from 'components/common/ErrorModal';
import { Checkbox } from 'components/common/Checkbox';

const formatNumberWithSpaces = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const removeSpaces = (number: string) => {
  return number.replace(/\s+/g, '');
};

const regexPercent = /^[0-9]*\.?[0-9]{0,2}$/;

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const TwoStep: React.FC<TwoStepProps> = ({
  setCurrentStep,
  selectedProductCode,
  sum,
  setSum,
  days,
  setDays,
  minSum,
  setMinSum,
  percent,
  setPercent,
  setSelectAccountNum,
  selectedGroups,
  setSelectedGroups,
  uploadedFiles,
  setUploadedFiles,
  selectedContract,
  setSelectedContract,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCollateral,
  setSelectedCollateral,
  setDocLink,
  docLink,
}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isKbeDropdownOpen, setIsKbeDropdownOpen] = useState(false);
  const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [kbeOptions, setKbeOptions] = useState<KBE[]>([]);
  const [selectedKbe, setSelectedKbe] = useState<KBE | null>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isContractDropdownOpen, setIsContractDropdownOpen] = useState(false);
  const [sumError, setSumError] = useState<string | null>(null);
  const [minSumError, setMinSumError] = useState<string | null>(null);
  const { userDataLight } = useAppContext();
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const kbeDropdownRef = useRef<HTMLDivElement>(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCollateralChecked, setIsCollateralChecked] = useState(false);
  const [collateralOptions, setCollateralOptions] = useState<CollateralOption[]>([]);
  const [isCollateralDropdownOpen, setIsCollateralDropdownOpen] = useState(false);
  const collateralDropdownRef = useRef(null);
  const [errorLink, setErrorLink] = useState<string | null>(null);

  const isUserRole = userDataLight?.roles.includes('MANAGER');

  useEffect(() => {
    axios
      .get(`${BASE_URL}ui/accounts`, { withCredentials: true })
      .then((response) => setAccounts(response.data))
      .catch((error) => {
        console.error('Error fetching accounts:', error);
        setError('Error fetching accounts');
      });
  }, []);

  useEffect(() => {
    if (isCollateralChecked) {
      axios
        .get(`${BASE_URL}dictionary/get?name=collateral`, {
          withCredentials: true,
        })
        .then((response) => setCollateralOptions(response.data))
        .catch((error) => {
          console.error('Error fetching collateral options:', error);
          setError('Error fetching collateral options');
        });
    } else {
      setSelectedCollateral(null);
    }
  }, [isCollateralChecked]);

  useEffect(() => {
    if (days && !isNaN(Number(days))) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + Number(days));
      setEndDate(newEndDate);
    } else {
      setEndDate(null);
    }
  }, [startDate, days]);

  useEffect(() => {
    if (endDate) {
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDays(diffDays.toString());
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (isAddingNewAccount) {
      axios
        .get(`${BASE_URL}dictionary/get?name=kbe`, {
          withCredentials: true,
        })
        .then((response) => setKbeOptions(response.data))
        .catch((error) => {
          console.error('Error fetching KBE options:', error);
          setError('Error fetching KBE options');
        });
    }
  }, [isAddingNewAccount]);

  useEffect(() => {
    setIsAddButtonDisabled(!(selectedKbe && accountNumber.length === 20));
  }, [selectedKbe, accountNumber]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target as Node)
      ) {
        setIsAccountDropdownOpen(false);
      }
      if (kbeDropdownRef.current && !kbeDropdownRef.current.contains(event.target as Node)) {
        setIsKbeDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedProductCode === 'TENDER') {
      axios
        .get(`${BASE_URL}ui/contract/list/egz`, {
          withCredentials: true,
        })
        .then((response) => setContracts(response.data))
        .catch((error) => {
          console.error('Error fetching contracts:', error);
          setError('Error fetching contracts');
        });
    }
  }, [selectedProductCode]);

  useEffect(() => {
    if (userDataLight.whiteLabel.length === 1) {
      setSelectedGroups([{ code: userDataLight.whiteLabel[0].code }]);
    }
  }, [userDataLight.whiteLabel]);

  useEffect(() => {
    if (selectedContract) {
      validateSum(sum);
      validateMinSum(minSum);
    }
  }, [selectedContract]);

  const validateSum = (sum: string) => {
    const value = Number(removeSpaces(sum));
    if (selectedProductCode === 'TENDER' && selectedContract) {
      if (value > selectedContract.contractSum) {
        setSumError('Сумма займа не может превышать сумму контракта');
      } else {
        setSumError(null);
      }
    } else {
      setSumError(null);
    }
  };

  const validateMinSum = (minSum: string) => {
    const minValue = Number(removeSpaces(minSum));
    const sumValue = Number(removeSpaces(sum));
    if (selectedProductCode === 'TENDER' && selectedContract) {
      if (minValue > sumValue || minValue > selectedContract.contractSum) {
        setMinSumError(
          'Минимальный порог для входа инвестора не может превышать сумму займа и сумму контракта',
        );
      } else {
        setMinSumError(null);
      }
    } else {
      if (minValue > sumValue) {
        setMinSumError('Минимальный порог для входа инвестора не должен превышать сумму займа');
      } else {
        setMinSumError(null);
      }
    }
  };

  const handleSumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeSpaces(e.target.value);
    if (!isNaN(Number(value))) {
      setSum(value);
      validateSum(value);
      validateMinSum(minSum);
    }
  };

  const handleMinSumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeSpaces(e.target.value);
    if (!isNaN(Number(value))) {
      setMinSum(value);
      validateMinSum(value);
    }
  };

  const handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (regexPercent.test(value)) {
      setPercent(value);
    }
  };

  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDays(e.target.value);
  };

  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
    setIsAccountDropdownOpen(false);
    setSelectAccountNum(account.id.toString());
  };

  const handleContractSelect = (contract: Contract) => {
    setSelectedContract(contract);
    setIsContractDropdownOpen(false);
    validateSum(sum);
    validateMinSum(minSum);
  };

  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
    if (!isAccountDropdownOpen) {
      setIsKbeDropdownOpen(false);
    }
  };

  const toggleKbeDropdown = () => {
    setIsKbeDropdownOpen(!isKbeDropdownOpen);
    if (!isKbeDropdownOpen) {
      setIsAccountDropdownOpen(false);
    }
  };

  const toggleContractDropdown = () => {
    setIsContractDropdownOpen(!isContractDropdownOpen);
  };

  const handleAddNewAccount = () => {
    setIsAddingNewAccount(true);
    setIsAccountDropdownOpen(false);
  };

  const handleKbeSelect = (kbe: KBE) => {
    setSelectedKbe(kbe);
    setIsKbeDropdownOpen(false);
  };

  const addAccount = async (kbe: number | null, iban: string) => {
    try {
      const response = await axios.post(
        `${BASE_URL}ui/accounts/add`,
        {
          kbe,
          iban,
        },
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.error('Error adding account:', error);
      setError('Error adding account');
      return null;
    }
  };

  const handleAddAccount = async () => {
    if (selectedKbe && accountNumber) {
      const newAccount = await addAccount(selectedKbe.id, accountNumber);
      if (newAccount) {
        setAccounts((prevAccounts) => [...prevAccounts, newAccount]);
        setSelectedAccount(newAccount);
        setIsAddingNewAccount(false);
      } else {
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const onlyAllGroup =
    userDataLight.whiteLabel.length === 1 && userDataLight.whiteLabel[0].code === 'ALL';

  const handleGroupSelect = (option: WhiteLabelOption) => {
    const newSelectedGroups = [...selectedGroups];
    const index = newSelectedGroups.findIndex((group) => group.code === option.code);
    if (index !== -1) {
      newSelectedGroups.splice(index, 1); // Remove the item if found
    } else {
      newSelectedGroups.push({ code: option.code }); // Add as an object
    }
    setSelectedGroups(newSelectedGroups); // Update state
  };

  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
      });
      const { uid } = response.data;
      setUploadedFiles((prevFiles) => [...prevFiles, { name: file.name, uid }]);
    } catch (error) {
      console.error('Ошибка при добавление', error);
      setError('Ошибка при добавление файла');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = (uid: string) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files.forEach((file) => handleFileUpload(file));
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleCollateralSelect = (collateral: any) => {
    setSelectedCollateral(collateral);
    setIsCollateralDropdownOpen(false);
  };

  const toggleCollateralDropdown = () => {
    if (collateralOptions.length > 0) {
      setIsCollateralDropdownOpen(!isCollateralDropdownOpen);
    }
  };

  const handleCollateralCheckboxChange = () => {
    setIsCollateralChecked(!isCollateralChecked);
    if (!isCollateralChecked) {
      setSelectedCollateral(null);
    }
  };

  const validateGoogleDriveLink = (link: string) => {
    const googleDrivePattern = /google\.com\/drive\/folders\//;
    return googleDrivePattern.test(link);
  };

  const handleGoogleDriveLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value.trim();
    if (!link || validateGoogleDriveLink(link)) {
      setDocLink(link); // Устанавливаем значение только если ссылка валидна или пустая
      setErrorLink(null); // Убираем ошибку
    } else {
      setErrorLink('Неправильный формат ссылки.');
    }
  };

  useEffect(() => {
    const isComplete =
      sum &&
      minSum &&
      percent &&
      days &&
      selectedAccount &&
      (!selectedProductCode || selectedProductCode !== 'TENDER' || selectedContract) &&
      (onlyAllGroup || selectedGroups.length > 0) &&
      !sumError &&
      !minSumError &&
      (!isCollateralChecked || (isCollateralChecked && selectedCollateral));
    setIsFormComplete(isComplete);
  }, [
    sum,
    minSum,
    percent,
    days,
    selectedAccount,
    selectedProductCode,
    selectedContract,
    selectedGroups,
    onlyAllGroup,
    sumError,
    minSumError,
    isCollateralChecked,
    selectedCollateral,
  ]);

  const closeModal = () => {
    setError(null);
  };

  const hasContent = days.trim() !== '';

  return (
    <Container>
      <FirstBlock>
        <Header>Основная информация</Header>
        <BlockInput>
          <InputText
            title='Какой объем средств вам нужен?'
            placeholder='20 000 000'
            value={formatNumberWithSpaces(sum)}
            onChange={handleSumChange}
            type='text'
            error={sumError}
          />
          <InputText
            title='Минимальный порог для входа инвестора'
            placeholder='0'
            value={formatNumberWithSpaces(minSum)}
            onChange={handleMinSumChange}
            type='text'
            error={minSumError}
          />
        </BlockInput>
        <BlockInput>
          <InputText
            title='Процентная ставка в месяц'
            placeholder='20 %'
            value={percent}
            onChange={handlePercentChange}
            type='text'
          />
          <CalendarBox>
            <label>На какой срок вам нужны средства (дней)</label>
            <Calendar>
              <DatePickerWrapper>
                <StyledDatePickerWrapper $hasContent={hasContent}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    dateFormat='dd.MM.yyyy'
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale={ru}
                    customInput={<StyledInputWithIcon $hasContent={hasContent} />}
                  />
                </StyledDatePickerWrapper>
                <CalendarIconWrapper src={CalendarIcons} alt='icon-calendar' />
              </DatePickerWrapper>
              <StyledInput
                type='number'
                value={days}
                onChange={handleDaysChange}
                placeholder='Кол-во дней'
                $hasContent={hasContent}
              />
            </Calendar>
          </CalendarBox>
        </BlockInput>
        {selectedProductCode === 'LOAN' && (
          <BlockInput>
            <FactoryBox>
              <Checkbox checked={isCollateralChecked} onChange={handleCollateralCheckboxChange} />
              <label>Имеется залог</label>
            </FactoryBox>
            {isCollateralChecked && (
              <DropdownContainer ref={collateralDropdownRef}>
                <label>Вид залога</label>
                <DropdownHeader
                  onClick={toggleCollateralDropdown}
                  isOpen={isCollateralDropdownOpen}
                  $hasContent={!!selectedCollateral}
                  $isDisabled={collateralOptions.length === 0}>
                  {selectedCollateral ? selectedCollateral.name : 'Выбрать вид залога'}
                  <DropdownArrow isOpen={isCollateralDropdownOpen} />
                </DropdownHeader>
                {isCollateralDropdownOpen && (
                  <DropdownListContainer>
                    {collateralOptions.map((collateral) => (
                      <DropdownListItem
                        key={collateral.id}
                        onClick={() => handleCollateralSelect(collateral)}>
                        {collateral.name}
                      </DropdownListItem>
                    ))}
                  </DropdownListContainer>
                )}
              </DropdownContainer>
            )}
          </BlockInput>
        )}
        <BlockInput>
          <DropdownContainer ref={accountDropdownRef}>
            <label>Выбрать счет</label>
            <DropdownHeader
              onClick={toggleAccountDropdown}
              isOpen={isAccountDropdownOpen}
              $hasContent={!!selectedAccount}
              $isDisabled={accounts.length === 0}>
              {selectedAccount ? selectedAccount.iban : 'Выбрать счет'}
              <DropdownArrow isOpen={isAccountDropdownOpen} />
            </DropdownHeader>
            {isAccountDropdownOpen && (
              <DropdownListContainer>
                {accounts.map((account) => (
                  <DropdownListItem key={account.id} onClick={() => handleAccountSelect(account)}>
                    {account.iban}
                  </DropdownListItem>
                ))}
                {isUserRole && (
                  <AddAccountItem onClick={handleAddNewAccount}>Добавить новый счет</AddAccountItem>
                )}
              </DropdownListContainer>
            )}
          </DropdownContainer>
          {selectedProductCode === 'TENDER' && (
            <DropdownContainer>
              <label>Выбрать контракт</label>
              <DropdownHeader
                onClick={toggleContractDropdown}
                isOpen={isContractDropdownOpen}
                $hasContent={!!selectedContract}
                $isDisabled={contracts.length === 0}>
                {selectedContract
                  ? `${selectedContract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(selectedContract.contractSum.toString())} тенге )`
                  : 'Выбрать контракт'}
                <DropdownArrow isOpen={isContractDropdownOpen} />
              </DropdownHeader>
              {isContractDropdownOpen && (
                <DropdownListContainer>
                  {contracts.map((contract) => (
                    <DropdownListItem
                      key={contract.id}
                      onClick={() => handleContractSelect(contract)}>
                      {`${contract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(contract.contractSum.toString())} тенге )`}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
          )}
        </BlockInput>
        {isAddingNewAccount && (
          <>
            <BlockInput>
              <InputText
                title='Номер счета'
                placeholder='Введите номер счета (20 чисел)'
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
              <DropdownContainer ref={kbeDropdownRef}>
                <label>Выбрать KBE</label>
                <DropdownHeader
                  onClick={toggleKbeDropdown}
                  isOpen={isKbeDropdownOpen}
                  $hasContent={!!selectedKbe}
                  $isDisabled={kbeOptions.length === 0}>
                  {selectedKbe ? selectedKbe.name : 'Выбрать KBE'}
                  <DropdownArrow isOpen={isKbeDropdownOpen} />
                </DropdownHeader>
                {isKbeDropdownOpen && (
                  <DropdownListContainer>
                    {kbeOptions.map((kbe) => (
                      <DropdownListItem key={kbe.id} onClick={() => handleKbeSelect(kbe)}>
                        {kbe.name}
                      </DropdownListItem>
                    ))}
                  </DropdownListContainer>
                )}
              </DropdownContainer>
              <NewButton
                text='Добавить'
                onClick={handleAddAccount}
                disabled={isAddButtonDisabled}
              />
            </BlockInput>
          </>
        )}
        {!onlyAllGroup && (
          <BoxTag>
            <p>Видимость заявки для групп:</p>
            <Tags>
              {userDataLight.whiteLabel.map((option: WhiteLabelOption) => (
                <Tag
                  key={option.code}
                  className={
                    selectedGroups.some((group) => group.code === option.code)
                      ? option.code === 'OMARKET'
                        ? 'smartcash'
                        : 'activeGroup'
                      : 'inactiveGroup'
                  }
                  onClick={() => handleGroupSelect(option)}>
                  {option.name}
                </Tag>
              ))}
            </Tags>
          </BoxTag>
        )}
      </FirstBlock>
      <SecondBlock>
        <label>Документация по сделке</label>
        <InputText
          title='Ссылка на гугл папку'
          placeholder='google.com/drive/folders/'
          value={docLink}
          onChange={handleGoogleDriveLinkChange}
          type='text'
          error={errorLink}
        />
        <p>Пожалуйста приложите нужные документы ( финансовая модель, выписка с банка, баланс )</p>
        <p>Max file size is 5mb. Supported file types are ( .jpg .png ) ( .docx .pdf )</p>
        <FileUploadArea
          onDrop={onDrop}
          onDragOver={onDragOver}
          onClick={() => document.getElementById('fileInput')?.click()}>
          Drag and drop files here or upload
          <FileUploadInput
            id='fileInput'
            type='file'
            accept='.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'
            onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
          />
        </FileUploadArea>
        {isLoading && <LoadingIndicator>Uploading...</LoadingIndicator>}
        <div>
          {uploadedFiles.map((file) => (
            <UploadedFile key={file.uid}>
              {file.name}
              <FileDeleteButton onClick={() => handleFileDelete(file.uid)}>x</FileDeleteButton>
            </UploadedFile>
          ))}
        </div>
        <ButtonBox>
          <NewButton
            text=''
            icon={ArrowLeft}
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
          />
          <NewButton
            text='Далее'
            icon={ArrowRight}
            onClick={() => setCurrentStep((prevStep) => prevStep + 1)}
            disabled={!isFormComplete}
          />
        </ButtonBox>
      </SecondBlock>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default TwoStep;
