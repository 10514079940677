import React from 'react';
import { ModalBackground, ErrorDialog } from './ErrorModal.style';
import ErrorIcon from 'assets/img/ErrorIcon.webp';
import { NewButton } from '../Button';

interface ErrorModalProps {
  headerError: string;
  textError: string;
  buttonClose: string;
  onClick: () => void;
}

export const ErrorModal = ({ headerError, textError, buttonClose, onClick }: ErrorModalProps) => {
  return (
    <>
      <ModalBackground>
        <ErrorDialog>
          <img src={ErrorIcon} alt='icon' />
          <h2>{headerError}</h2>
          <p>{textError}</p>
          <NewButton text={buttonClose} onClick={() => onClick()} />
        </ErrorDialog>
      </ModalBackground>
    </>
  );
};
