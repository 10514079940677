import styled from 'styled-components';

const CheckboxContainer = styled.div`
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UncheckedBox = styled.div`
  border-radius: 3px;
  border: 1px solid #424242;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
  width: 100%;
  height: 100%;
`;

export { CheckboxContainer, UncheckedBox };
