import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/investor`;

export const fetchTableInvestorList = async (page = 0) => {
  try {
    const response = await axios.get(`${BASE_URL}/request?page=${page}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка загрузки данных:', error);
    throw error;
  }
};
