import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const FileLink = styled.a`
  align-items: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  background: var(--primary-300, #5784f7);
  align-self: stretch;
  text-decoration: none;
  color: var(--text-text-01, #f4f4f4);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  cursor: pointer;
  &:hover {
    background: var(--primary-700, #0f62fe);
  }
`;

export const DeleteIcon = styled.div`
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 16px;
  label {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
  p {
    color: var(--neutral-500, var(--neutral-500, #848484));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
`;

export const FileUploadArea = styled.div`
  border: 2px dashed #9aa4b0;
  padding: 10px;
  display: flex;
  height: 96px;
  color: #5784f7;
  cursor: pointer;
  position: relative;
`;

export const FileUploadInput = styled.input`
  display: none;
`;

export const WarningMessage = styled.span`
  color: red;
  font-size: 14px;
`;
